import React from "react";
import { Route, Routes } from "react-router-dom";
import PmHealthcare from "../Components/PmHealthcare";
import SkylineBank from "../Components/SkylineBank";
import RetailCycle from "../Components/RetailCycle";
import { AppContent } from "../App";
import DocuAssist from "../Components/DemoBots/DocuAssist";
import PhonepeAssist from "../Components/DemoBots/PhonepeAssist";
import SupremeCourtDataAssistant from "../Components/DemoBots/SupremeCourtDataAssistant";
import AvatarVoice from "../Components/DemoBots/AvatarVoice";
import ElectoBot from "../Components/DemoBots/ElectoBot";
import ScriptPage from "../Components/ScriptPage";
import HealthcarePortal from "../Components/healthcare-portal/HealthcarePortal";
import CustomerSupportBot from "../Components/DemoBots/CustomerSupportBot";
import VillaGenie from "../Components/DemoBots/VillaGenie";
import OutBoundCallBot from "../Components/DemoBots/OutBoundCallBot";
import SalesBot from "../Components/DemoBots/SalesBot";
import LogInsights from "../Components/DemoBots/LogInsights";

const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<AppContent />} />
        <Route path="/pm-healthcare" element={<PmHealthcare />} />
        <Route path="/skyline-bank" element={<SkylineBank />} />
        <Route path="/cycle-mart" element={<RetailCycle />} />
        <Route path="/legal-assistant" element={<DocuAssist />} />
        <Route path="/phonepe-assistant" element={<PhonepeAssist />} />
        <Route path="/outbound-call-assistant" element={<OutBoundCallBot />} />
        <Route
          path="/court-assistant"
          element={<SupremeCourtDataAssistant />}
        />
        <Route path="/avatar-voice" element={<AvatarVoice />} />
        <Route path="/electo-bot" element={<ElectoBot />} />
        <Route path="/launch-demo/:id" element={<ScriptPage />} />

        <Route path="/healthcare-portal/*" element={<HealthcarePortal />} />
        <Route path="/customer-support-bot" element={<CustomerSupportBot />} />
        <Route path="/villa-genie" element={<VillaGenie />} />
        <Route path="/sales-assistant" element={<SalesBot />} />
        <Route path="/log-insights" element={<LogInsights />} />
      </Routes>
    </>
  );
};

export default AppRouter;
